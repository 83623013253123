import React from "react";
import { graphql, Link, navigate } from "gatsby";
import useAuth from "../hooks/useAuth";

import ShopLayout from "../components/new-layout";
import Breadcrumbs from "../components/breadcrumbs";

import { isBrowser } from "../utils/isBrowser";
import { convertToSlug } from "../utils/converters";
import Paws from "../components/paws";

const CategoryList = ({ data, pageContext }) => {
  const { state } = useAuth();
  const isAuthenticated =
    state !== undefined &&
    state.isLoggedIn &&
    state.isActive &&
    !state.isExpired;

  if (isBrowser && !isAuthenticated) {
    navigate("/");
    return null;
  }

  const category = data.category;

  const { relativePath } = pageContext;

  let links = [
    ["/platform", "Home"],
    ["/store", "store"],
    [relativePath, category.name],
  ];

  let availableSoonContent = (
    <div className="flex flex-col md:flex-row">
      <div className="flex-grow">
        <div className="flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center lg:justify-between mb-6">
          <div className="text-gray-700">Available Soon.</div>
        </div>
      </div>
    </div>
  );

  return (
    <ShopLayout>
      <Breadcrumbs links={links} />
      <div className="relative">
        <div className="absolute top-0 bottom-0 right-0 left-0 overflow-hidden z-0">
          <Paws
            className="fill-current text-yellow-300 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "19deg",
              right: "10%",
              top: 10,
            }}
          />
          <Paws
            className="fill-current text-pink-500 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "10deg",
              right: "40%",
              top: 115,
            }}
          />
          <Paws
            className="fill-current text-blue-300 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "-40deg",
              left: "1%",
              top: 200,
            }}
          />
          <Paws
            className="fill-current text-blue-100 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "-40deg",
              left: "44%",
              top: 700,
            }}
          />
          <Paws
            className="fill-current text-pink-300 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "27deg",
              right: "1%",
              top: 800,
            }}
          />
          <Paws
            className="fill-current text-yellow-500 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "27deg",
              left: "30%",
              top: 1350,
            }}
          />
        </div>
        <div className="container lg:max-w-screen-lg">
          <div className="flex items-center justify-between">
            <h1 className="font-display text-2xl text-blue-900 mt-8 mb-10 lg:mt-12 lg:mb-16 capitalize">
              {category.name}
            </h1>
          </div>
          {category.categories.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-8 xl:gap-10 text-white mb-20 lg:mb-28">
              {category.categories.map((c) => (
                <Link
                  to={`/store/category/${convertToSlug(c.name)}`}
                  key={c._id}
                >
                  <div className="bg-pink-700 cursor-pointer rounded-lg relative p-2">
                    <div className="border-2 border-dashed border-white rounded-lg h-40 flex items-center justify-center">
                      <span className="text-3xl font-display">{c.name}</span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            availableSoonContent
          )}
        </div>
      </div>
    </ShopLayout>
  );
};

export default CategoryList;

export const query = graphql`
  query CategoryListQuery($categoryId: String!) {
    category(_id: { eq: $categoryId }) {
      _id
      name
      categories {
        _id
        name
      }
    }
  }
`;
